// ** services imports
import { BaseService } from '../base/base.service'

// ** types imports
import {
    EmailSettignsApiResponse,
    EmailsListApiResponse,
    GenerateTitleApiResponse,
    TitlesHistoryApiHeaders,
    TitlesHistoryApiResponse,
    TitlesHistoryParams,
} from './types'

export default class UserService extends BaseService {
    private prefix = this.getBaseUrl.concat('user/')

    /**
     * Use to change user's password
     *
     * @param newPassword the new pass phrase
     */
    public async changePassword(newPassword: string) {
        const url = this.prefix.concat('change_password')

        const reqBody = {
            new_password: newPassword,
        }

        return this.post(url, reqBody)
    }

    /**
     * Use to get user's email settings
     */
    public async emailSettings() {
        const url = this.prefix.concat('email_settings')

        return this.get<EmailSettignsApiResponse>(url)
    }

    /**
     * Use to update user's timezone
     */
    public async updateEmailSettings(
        frequency: number,
        timezoneId?: number,
        time?: string,
        day?: number
    ) {
        const url = this.prefix.concat('email_settings')

        const reqBody = {
            frequency,
            ...(timezoneId && { timezone_id: timezoneId }),
            ...(time && { time }),
            ...(day && { day }),
        }

        return this.post(url, reqBody)
    }

    /**
     * Use to get user emails list
     */
    public async emailsList() {
        const url = this.prefix.concat('user_emails')

        return this.get<EmailsListApiResponse>(url)
    }

    /**
     * Use to add new email to user's emails list
     *
     * @param email the email
     */
    public async addNewEmail(email: string) {
        const url = this.prefix.concat('user_email')

        const reqBody = { email }

        return this.post(url, reqBody)
    }

    /**
     * Use to generate demo titles
     *
     * @param interest related interest
     * @param country related country
     */
    public async generateDemoTitles(interest_id: number, country_id?: number) {
        const url = this.prefix.concat('generate_titles')

        const reqBody = {
            interest_id,
            ...(country_id && { country_id }),
        }

        return this.post<GenerateTitleApiResponse>(url, reqBody)
    }

    /**
     * Use to titles history
     */
    public async titlesHistory(params?: TitlesHistoryParams) {
        const url = this.prefix.concat('titles_history')

        const headers: TitlesHistoryApiHeaders = {
            ...(params?.start && { start: params.start }),
            ...(params?.start && { limit: params.limit || 10 }),
        }

        return this.get<TitlesHistoryApiResponse>(url, headers)
    }
}
