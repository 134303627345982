// ** react imports
import {
    DetailedHTMLProps,
    forwardRef,
    InputHTMLAttributes,
    LabelHTMLAttributes,
    isValidElement,
    ReactNode,
    Ref,
} from 'react'

// ** third party imports
import { Control, Controller } from 'react-hook-form'

type DefaultInputProps = DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>
type DefaultLabelProps = DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
>

export interface InputProps extends DefaultInputProps {
    label?: string
    labelProps?: DefaultLabelProps
    control?: Control<any, any>
    hint?: string
    error?: string

    startIcon?: ReactNode
    endIcon?: ReactNode

    // styling
    containerClass?: string
    inputComponentContainer?: string
}

const Input = forwardRef(
    (
        {
            label,
            hint,
            error,
            control,
            containerClass,
            inputComponentContainer,
            startIcon,
            endIcon,
            ...props
        }: InputProps,
        ref: Ref<any>
    ) => {
        const renderInputContainerClass = () => {
            let classes = inputComponentContainer?.split(' ') || []

            if (error) classes.push('border-red-500')

            return classes.join(' ')
        }

        const renderClass = () => {
            let classes = props.className?.split(' ') || []

            if (error) classes.push('placeholder:text-red-500')

            return classes.join(' ')
        }

        const renderLabel = (
            <label
                {...(props.id && { htmlFor: props.id })}
                className={props.className || ''}
            >
                {label}
            </label>
        )

        const renderHint = (
            <span className={`text-xs text-gray-400`}>{hint}</span>
        )

        const renderError = (
            <span className={`text-xs text-red-500`}>{error}</span>
        )

        return (
            <div className={`flex flex-col ${containerClass || ''}`}>
                {label && renderLabel}

                <div
                    className={`flex items-center border rounded-md bg-gray-200 ${
                        renderInputContainerClass() || ''
                    }`}
                    onClick={props.onClick}
                >
                    {isValidElement(startIcon) && startIcon}
                    {control ? (
                        <Controller
                            name={props.name || ''}
                            control={control}
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <input
                                    ref={ref}
                                    type='text'
                                    {...props}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    className={`bg-transparent text-sm outline-none w-full px-6 py-4 ${renderClass()}`}
                                />
                            )}
                        />
                    ) : (
                        <input
                            ref={ref}
                            type='text'
                            {...props}
                            className={`bg-transparent text-sm outline-none w-full px-6 py-4 ${renderClass()}`}
                        />
                    )}
                    {isValidElement(endIcon) && endIcon}
                </div>

                {hint && renderHint}

                {error && renderError}
            </div>
        )
    }
)

Input.displayName = 'Input'

export default Input
