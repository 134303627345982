// ** custom component imports
import ActiveLink from '../components/ActiveLink'

const NavigationBar = () => {
    return (
        <nav className='flex justify-center items-center bg-white'>
            <div className='container flex items-center gap-x-2 py-3 px-5'>
                <ActiveLink to='/topic-selection'>Topic Selection</ActiveLink>
                <ActiveLink to='/email-setting'>Email Setting</ActiveLink>
                <ActiveLink to='/demo'>Demo</ActiveLink>
                <ActiveLink to='/billing'>Billing</ActiveLink>
                <ActiveLink to='/history'>History</ActiveLink>
            </div>
        </nav>
    )
}

export default NavigationBar
