// ** components imports
import Snack, { SnackProps } from '../components/Snack'

// ** third party imports
import toast, { Toast } from 'react-hot-toast'

type ExcludedToastProps = 'children' | 'message' | 'onDismiss'
type ToastOptions = Partial<
    Pick<
        Toast,
        | 'id'
        | 'icon'
        | 'duration'
        | 'ariaProps'
        | 'className'
        | 'style'
        | 'position'
        | 'iconTheme'
    >
>
type ExtendedSnackProps = Omit<SnackProps, ExcludedToastProps> & ToastOptions

export const toaster = (message: string, options?: ExtendedSnackProps) =>
    toast(
        (t) =>
            Snack({
                message,
                onDismiss: () => toast.dismiss(t.id),
            }),
        {
            ...options,
            style: {
                ...options?.style,
                background: 'transparent',
                padding: 0,
                boxShadow: 'none',
            },
        }
    )
