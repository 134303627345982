import { configureStore } from '@reduxjs/toolkit'
import { userReducer } from './featues/user'

const store = configureStore({
    reducer: {
        user: userReducer,
    },
})

export default store
