// ** react imports
import { useState } from 'react'

// ** react router imports
import { Link } from 'react-router-dom'

// ** layouts imports
import SignUpLayout from '../../layouts/auth/SignUp'
import SignUpConfirmLayout from '../../layouts/auth/SignUpConfirm'

// ** third party imports
import { Helmet } from 'react-helmet-async'

const SignUp = () => {
    // ** states
    const [username, setUsername] = useState('')
    const [step, setStep] = useState(1)

    // ** methods
    const handleNextStep = (email: string) => {
        setUsername(email)
        setStep(2)
    }

    return (
        <div>
            <Helmet>
                <title>Sign Up | Trending Title</title>
            </Helmet>

            <h1 className='text-2xl text-center text-indigo-900'>
                Welcome to <span className='font-bold'>Trending Title</span>
            </h1>

            <div className='my-4'>
                {step === 1 && <SignUpLayout onNextStep={handleNextStep} />}
                {step === 2 && username && (
                    <SignUpConfirmLayout username={username} />
                )}
            </div>

            <div className='flex items-center justify-center w-full'>
                <p className='text-gray-500 text-sm'>
                    Already have an account?&nbsp;&nbsp;
                    <Link to='/auth/signin'>
                        <a className='text-violet-600 hover:underline'>
                            Sign in instead
                        </a>
                    </Link>
                </p>
            </div>
        </div>
    )
}

export default SignUp
