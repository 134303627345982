// ** react router imports
import { Outlet } from 'react-router-dom'

const AuthLayout = () => {
    return (
        <main className='flex flex-col flex-1 bg-gray-200'>
            <section className='flex flex-col md:flex-row flex-1'>
                <div className='flex flex-col w-full md:w-3/5 lg:w-2/3'>
                    {/* TODO: setup an illustration */}
                </div>
                <div className='flex flex-col justify-center items-center gap-y-5 bg-white w-full md:w-2/5 lg:w-1/3 flex-1 px-5 lg:px-10'>
                    <Outlet />
                </div>
            </section>
        </main>
    )
}

export default AuthLayout
