// ** react imports
import { createRef, useState } from 'react'

// ** react router imports
import { useNavigate } from 'react-router-dom'

// ** store imports
import { useAppDispatch } from '../../store/types'
import { updateUser } from '../../store/featues/user'

// ** custom components imports
import Input from '../../components/Input'
import Button from '../../components/Button'

// ** third party imports
import { Resolver, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useMutation } from '@tanstack/react-query'
import Recaptcha from 'react-google-recaptcha'

// ** services imports
import AuthService from '../../services/auth/auth.service'

// ** configs imports
import authConfig from '../../configs/auth.config'

// ** utils imports
import { toaster } from '../../utils'

const schema = yup.object().shape({
    password: yup
        .string()
        .min(8, 'Password length must be at least 8 characters')
        .required('Please enter your password'),
    confirm_password: yup
        .string()
        .oneOf([yup.ref('password')], 'Password mismatch'),
})

const defaultValues = {
    password: '',
    confirm_password: '',
}

type FormData = {
    password: string
    confirm_password: string
}

const authApi = new AuthService()

interface ChangePasswordProps {
    username: string
}

const ChangePassword = (props: ChangePasswordProps) => {
    // ** vars
    const recaptchaRef = createRef<Recaptcha>()

    // ** states
    const [recaptchaToken, setRecaptchaToken] = useState<string>('')

    // ** hooks
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(schema) as Resolver<FormData>,
    })

    const { isPending: changePasswordLoading, mutate: handleChangePassword } =
        useMutation({
            mutationFn: (passwd: string) =>
                authApi.changePassword({
                    username: props.username,
                    password: passwd,
                    token: recaptchaToken,
                }),
            onSuccess: (response) => {
                if (response?.status === 1) {
                    const { token, ...info } = response.data!
                    localStorage.setItem(authConfig.tokenKey, token)
                    localStorage.setItem(authConfig.info, JSON.stringify(info))
                    dispatch(updateUser({ ...response.data }))
                    navigate('/topic-selection')
                    toaster('Logged in successfully', {
                        severity: 'success',
                    })
                }
            },
        })

    const handleConfirmForgetPassword = (params: FormData) => {
        if (!recaptchaRef.current?.getValue()) {
            toaster('Please check recaptcha first', { severity: 'error' })
            return
        }

        handleChangePassword(params.password)

        // reset recaptcha
        recaptchaRef.current?.reset()
    }

    return (
        <form
            className='flex flex-col gap-y-5 w-full'
            onSubmit={handleSubmit(handleConfirmForgetPassword)}
        >
            <Input
                type='password'
                placeholder='Password'
                name='password'
                control={control}
                error={errors.password?.message}
                tabIndex={1}
                autoFocus
            />
            <Input
                type='password'
                placeholder='Confirm Password'
                name='confirm_password'
                control={control}
                error={errors.confirm_password?.message}
                tabIndex={2}
            />

            <div className='flex justify-center'>
                <Recaptcha
                    size='normal'
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                    onChange={(token) => {
                        if (token) setRecaptchaToken(token)
                    }}
                    tabindex={3}
                />
            </div>

            <Button type='submit' loading={changePasswordLoading} tabIndex={4}>
                Change Password
            </Button>
        </form>
    )
}

export default ChangePassword
