// ** react imports
import { Ref, useEffect, useRef } from 'react'

export function useClickAway(handler: () => void): any {
    const domNode: Ref<HTMLElement | null> = useRef<HTMLElement | null>(null)

    useEffect(() => {
        let maybeHandler = (event: any) => {
            if (!domNode.current?.contains(event.target)) {
                handler()
            }
        }

        document.addEventListener('mousedown', maybeHandler)

        return () => {
            document.removeEventListener('mousedown', maybeHandler)
        }
    })

    return domNode
}
