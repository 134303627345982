// ** react imports
import { PropsWithChildren, Ref, useEffect, useState } from 'react'

// ** hooks imports
import { useClickAway } from '../hooks'

type ModalType = {
    open: boolean
    dismissable?: boolean

    // styling
    contentContainerClass?: string

    // methods
    onClose: () => void
}

const Modal = ({
    open,
    dismissable = true,
    contentContainerClass,
    children,
    ...props
}: PropsWithChildren<ModalType>) => {
    // ** states
    const [modalOpen, setModalOpen] = useState(false)

    // ** hooks
    const ref = useClickAway(() => {
        if (dismissable) props.onClose()
    })

    useEffect(() => {
        setModalOpen(open)
    }, [open])

    return (
        <div
            className='relative z-100'
            aria-labelledby='modal-title'
            role='dialog'
            aria-modal='true'
        >
            <div
                className={`${
                    modalOpen ? 'fixed' : 'hidden'
                } inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-1`}
            ></div>

            <div
                className={`${
                    modalOpen ? 'fixed' : 'hidden'
                } inset-0 z-10 overflow-y-auto`}
            >
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                    <div
                        ref={ref as Ref<HTMLDivElement>}
                        className={`relative min-w-[50rem] w-fit transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                            contentContainerClass || ''
                        }`}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
