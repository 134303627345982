// ** react imports
import { createRef, useState } from 'react'

// ** react router imports
import { Link, useNavigate } from 'react-router-dom'

// ** custom components imports
import Input from '../../components/Input'
import Button from '../../components/Button'

// ** store imports
import { useAppDispatch } from '../../store/types'
import { updateUser } from '../../store/featues/user'

// ** third party imports
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useMutation } from '@tanstack/react-query'
import Recaptcha from 'react-google-recaptcha'
import { Helmet } from 'react-helmet-async'

// ** services imports
import AuthService from '../../services/auth/auth.service'
import { SigninParams } from '../../services/auth/types'

// ** configs
import authConfig from '../../configs/auth.config'

// ** utils
import { toaster } from '../../utils'

const schema = yup.object().shape({
    username: yup
        .string()
        .email('Email is not valid')
        .required('Please enter your email'),
    password: yup
        .string()
        .min(8, 'Password length must be at least 8 characters')
        .required('Please enter your password'),
})

const defaultValues = {
    username: '',
    password: '',
}

type FormData = {
    username: string
    password: string
}

const authApi = new AuthService()

const SignIn = () => {
    // ** states
    const [recaptchaToken, setRecaptchaToken] = useState('')

    // ** hooks
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const recaptchaRef = createRef<Recaptcha>()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(schema),
    })

    const { isPending: signinLoading, mutate: handleSignIn } = useMutation({
        mutationFn: (params: SigninParams) =>
            authApi.signin({ ...params, token: recaptchaToken }),
        onSuccess: (response) => {
            if (response?.status === 1 && response.code === 1100) {
                const { token, ...info } = response.data!

                localStorage.setItem(authConfig.tokenKey, token)
                localStorage.setItem(authConfig.info, JSON.stringify(info))
                dispatch(updateUser({ ...response.data }))
                navigate('/topic-selection')
                toaster('Logged in successfully', { severity: 'success' })
            }
        },
    })

    const handleSignup = ({ username, password }: FormData) => {
        if (!recaptchaRef.current?.getValue()) {
            toaster('Please check recaptcha first', { severity: 'error' })
            return
        }

        handleSignIn({ username, password })

        // reset recaptcha
        recaptchaRef.current?.reset()
    }

    return (
        <div>
            <Helmet>
                <title>Sign In | Trending Title</title>
            </Helmet>

            <h1 className='text-2xl text-center text-indigo-900'>
                Welcome to <span className='font-bold'>Trending Title</span>
            </h1>

            <form
                className='flex flex-col gap-y-5 w-full mt-4'
                onSubmit={handleSubmit(handleSignup)}
            >
                <div className='flex flex-col gap-y-3'>
                    <Input
                        placeholder='Email'
                        name='username'
                        control={control}
                        error={errors.username?.message}
                        tabIndex={1}
                        autoFocus
                    />
                    <Input
                        placeholder='Passowrd'
                        type='password'
                        name='password'
                        control={control}
                        error={errors.password?.message}
                        tabIndex={2}
                    />
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-x-2'>
                            <input
                                type='checkbox'
                                className='accent-violet-600'
                                id='remember-me-toggle-inp'
                            />
                            <label htmlFor='remember-me-toggle-inp'>
                                {' '}
                                Remember Me{' '}
                            </label>
                        </div>

                        <Link to='/auth/forget_password'>
                            <a className='text-violet-600 text-base hover:underline'>
                                Forget Password
                            </a>
                        </Link>
                    </div>
                </div>

                <div className='flex justify-center'>
                    <Recaptcha
                        ref={recaptchaRef}
                        sitekey={
                            process.env.REACT_APP_RECAPTCHA_SITE_KEY as string
                        }
                        onChange={(token) => {
                            if (token) setRecaptchaToken(token)
                        }}
                    />
                </div>

                <Button type='submit' loading={signinLoading}>
                    Sign In
                </Button>

                <div className='flex items-center justify-center w-full'>
                    <p className='text-gray-500 text-sm'>
                        New on our platform?&nbsp;&nbsp;
                        <Link to='/auth/signup' className='text-violet-600'>
                            Create an account
                        </Link>
                    </p>
                </div>
            </form>
        </div>
    )
}

export default SignIn
