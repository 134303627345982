// ** react router imports
import { RouteObject } from 'react-router-dom'

// ** views imports
import SignIn from '../views/auth/SignIn'
import SignUp from '../views/auth/SignUp'
import ForgetPassword from '../views/auth/ForgetPassword'

const authRoutes: Array<RouteObject> = [
    {
        path: 'signin',
        element: <SignIn />,
    },
    {
        path: 'signup',
        element: <SignUp />,
    },
    {
        path: 'forget_password',
        element: <ForgetPassword />,
    },
]

export default authRoutes
