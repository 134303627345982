// ** react router imports
import { Outlet } from 'react-router-dom'

const RootLayout = () => {
    return (
        <div className='flex flex-col h-screen'>
            <Outlet />
        </div>
    )
}

export default RootLayout
