// ** react imports
import { useState } from 'react'

// ** custom components imports
import { Button } from '../../components'
import Input from '../../components/Input'

// ** third party imports
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'
import { Resolver, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Icon } from '@iconify/react'
import { useToggle } from 'usehooks-ts'
import { Helmet } from 'react-helmet-async'

// ** services imports
import UserService from '../../services/user/user.service'

// ** utils imports
import { toaster } from '../../utils'

type ChangeUserPassword = {
    password: string
    confirm_password: string
}

// ** vars
const tabs = ['Security']
const schema = yup.object().shape({
    password: yup
        .string()
        .min(8, 'Password must be 8 characters at least')
        .required('Please enter password'),
    confirm_password: yup
        .string()
        .oneOf([yup.ref('password')], 'Password mismatch'),
})
const defaultValues: ChangeUserPassword = {
    password: '',
    confirm_password: '',
}
const usersApi = new UserService()

const Settings = () => {
    // ** states
    const [activeTab, setActiveTab] = useState(1)

    // ** hooks
    const {
        control,
        getValues,
        formState: { errors },
        handleSubmit,
    } = useForm<ChangeUserPassword>({
        defaultValues,
        resolver: yupResolver(schema) as Resolver<ChangeUserPassword>,
    })

    const [showPassword, toggleShowPassword] = useToggle()
    const [showConfirmPassword, toggleShowConfirmPassword] = useToggle()

    const { isPending: changePasswordLoading, mutate: handleChangePassword } =
        useMutation({
            mutationFn: () => usersApi.changePassword(getValues('password')),
            onSuccess: (response) => {
                if (response?.status === 1)
                    toaster('Your password changed successfully')
            },
        })

    // ** methods
    const handlePrepareToChangePassword = () => {
        handleChangePassword()
    }

    return (
        <section className='flex flex-col flex-1 gap-y-3'>
            <Helmet>
                <title>Settings | Trending Title</title>
            </Helmet>

            <ul className='flex items-center gap-x-2 w-full' role='tablist'>
                {tabs.map((tab, i) => (
                    <li
                        key={tab}
                        role='tab'
                        className={`rounded ${
                            i + 1 === activeTab
                                ? 'bg-violet-600 text-white'
                                : 'text-violet-600 border border-violet-600 hover:bg-violet-600/10'
                        } cursor-pointer w-40 text-center py-2 px-4`}
                        onClick={() => setActiveTab(i + 1)}
                    >
                        {tab}
                    </li>
                ))}
            </ul>

            {activeTab === 1 && (
                <div className='bg-white rounded shadow py-4 px-2'>
                    <form
                        className='flex flex-col gap-y-2'
                        onSubmit={handleSubmit(handlePrepareToChangePassword)}
                    >
                        <div className='flex items-start gap-x-2'>
                            <Input
                                control={control}
                                placeholder='Enter new password'
                                name='password'
                                type={showPassword ? 'text' : 'password'}
                                endIcon={
                                    showPassword ? (
                                        <Icon
                                            icon='mdi:eye-off-outline'
                                            className='w-10 text-gray-400 cursor-pointer'
                                            fontSize={20}
                                            onClick={toggleShowPassword}
                                        />
                                    ) : (
                                        <Icon
                                            icon='mdi:eye-outline'
                                            className='w-10 text-gray-400 cursor-pointer'
                                            fontSize={20}
                                            onClick={toggleShowPassword}
                                        />
                                    )
                                }
                                error={errors.password?.message}
                            />
                            <Input
                                control={control}
                                placeholder='Enter new password again'
                                name='confirm_password'
                                type={showConfirmPassword ? 'text' : 'password'}
                                endIcon={
                                    showConfirmPassword ? (
                                        <Icon
                                            icon='mdi:eye-off-outline'
                                            className='w-10 text-gray-400 cursor-pointer'
                                            fontSize={20}
                                            onClick={toggleShowConfirmPassword}
                                        />
                                    ) : (
                                        <Icon
                                            icon='mdi:eye-outline'
                                            className='w-10 text-gray-400 cursor-pointer'
                                            fontSize={20}
                                            onClick={toggleShowConfirmPassword}
                                        />
                                    )
                                }
                                error={errors.confirm_password?.message}
                            />
                        </div>

                        <Button
                            type='submit'
                            loading={changePasswordLoading}
                            className='w-fit'
                        >
                            {' '}
                            Update Password{' '}
                        </Button>
                    </form>
                </div>
            )}
        </section>
    )
}

export default Settings
