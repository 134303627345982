// ** next imports
import { useNavigate } from 'react-router-dom'

// ** store imports
import { logoutUser } from '../store/featues/user'

// ** custom components imports
import { Button } from '../components'

// ** types imports
import { useAppDispatch } from '../store/types'

const AppBar = () => {
    // ** hooks
    const dispatch = useAppDispatch()
    const navigation = useNavigate()

    // ** methods
    const handleLogout = () => {
        dispatch(logoutUser())
        navigation('/auth/signin')
    }

    return (
        <header className='flex justify-center items-center bg-white border-b border-gray-300'>
            <div className='container flex justify-between items-center w-full py-3 px-5'>
                <div className='flex items-center gap-x-4'>
                    <h1 className='font-bold text-lg'>Trending Title</h1>
                </div>

                <div className=''>
                    <Button onClick={handleLogout}>logout</Button>
                </div>
            </div>
        </header>
    )
}

export default AppBar
