// ** react imports
import { createRef, useState } from 'react'

// ** custom components imports
import Input from '../../components/Input'
import Button from '../../components/Button'

// ** third party imports
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useMutation } from '@tanstack/react-query'
import Recaptcha from 'react-google-recaptcha'

// ** services imports
import AuthService from '../../services/auth/auth.service'

// ** utils imports
import { toaster } from '../../utils'

const schema = yup.object().shape({
    username: yup
        .string()
        .email('Email is not valid')
        .required('Please enter your email'),
})

const defaultValues = {
    username: '',
}

type FormData = {
    username: string
}

const authApi = new AuthService()

interface ForgetPasswordProps {
    onNextStep: (username: string) => void
}

const ForgetPassword = (props: ForgetPasswordProps) => {
    // ** vars
    const recaptchaRef = createRef<Recaptcha>()

    // ** states
    const [recaptchaToken, setRecaptchaToken] = useState('')

    // ** hooks
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(schema),
    })

    const {
        isPending: forgetPasswordLoading,
        mutate: handleRequestForgetPassword,
    } = useMutation({
        mutationFn: (email: string) =>
            authApi.forgetPassword({ username: email, token: recaptchaToken }),
        onSuccess: (response, username) => {
            // if (response.status === 1)
            //     return props.onNextStep(username);
            // toaster(response?.message || 'Error occured', {
            //     severity: 'error',
            // });
            // setValue('username', '');
        },
    })

    const handleSignup = ({ username }: FormData) => {
        if (!recaptchaRef.current?.getValue()) {
            toaster('Please check recaptcha first', { severity: 'error' })
            return
        }

        handleRequestForgetPassword(username)

        // reset recaptcha
        recaptchaRef.current?.reset()
    }

    return (
        <form
            className='flex flex-col gap-y-5 w-full'
            onSubmit={handleSubmit(handleSignup)}
        >
            <Input
                placeholder='Email'
                name='username'
                control={control}
                error={errors.username?.message}
                tabIndex={1}
                autoFocus
            />

            <div className='flex justify-center'>
                <Recaptcha
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                    tabindex={2}
                    onChange={(token) => {
                        if (token) setRecaptchaToken(token)
                    }}
                />
            </div>

            <Button type='submit' loading={forgetPasswordLoading} tabIndex={3}>
                Send Code
            </Button>
        </form>
    )
}

export default ForgetPassword
