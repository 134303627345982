// ** react imports
import { ChangeEvent, FC, useEffect, useState } from 'react'

// ** custom components imports
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Chip from '../../components/Chip'
import Modal from '../../components/Modal'
import Select, { DropdownOption } from '../../components/Select'
import Input from '../../components/Input'

// ** third party imports
import { useMutation, useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

// ** services imports
import TimezoneService from '../../services/timezones/timezone.service'
import UserService from '../../services/user/user.service'

// ** models imports
import { TimezoneModel, UserEmailSettingModel } from '../../models'

type EmailSettings = Pick<
    UserEmailSettingModel,
    'frequency' | 'time' | 'day_of_period'
> & {
    timezone: Partial<TimezoneModel>
}

const timezoneApi = new TimezoneService()
const userApi = new UserService()

const EmailSetting: FC = () => {
    // ** states
    const [newEmailModalOpen, setNewEmailModalOpen] = useState(false)
    const [defaultEmailSettings, setDefaultEmailSettings] =
        useState<EmailSettings | null>(null)
    const [newEmail, setNewEmail] = useState('')
    const [newFrequency, setNewFrequency] = useState('')
    const [newTimezone, setNewTimezone] = useState('')
    const [newTime, setNewTime] = useState('')
    const [newDay, setNewDay] = useState('')

    // ** hooks
    const {
        isLoading: emailsLoading,
        data: emailsList,
        refetch: handleFetchEmails,
    } = useQuery({
        queryKey: ['user_emails'],
        queryFn: () => userApi.emailsList(),
        refetchOnWindowFocus: false,
        retry: 0,
    })

    const { isLoading: timezonesLoading, data: timezones } = useQuery({
        queryKey: ['timezones_list'],
        queryFn: () => timezoneApi.timezones(),
        refetchOnWindowFocus: false,
        retry: false,
    })

    const {
        isLoading: emailSettingsLoading,
        refetch: handleFetchEmailSettings,
        data: emailSettingsRes,
    } = useQuery({
        queryKey: ['email_settings'],
        queryFn: () => userApi.emailSettings(),
    })

    const { isPending: newEmailLoading, mutate: handleAddNewEmail } =
        useMutation({
            mutationFn: () => userApi.addNewEmail(newEmail),
            onSuccess: (response) => {
                if (response?.status == 1) {
                    handleCloseNewEmailModal()
                    handleFetchEmails()
                }
            },
        })

    const { isPending: updateSettingsLoading, mutate: handleUpdateSettings } =
        useMutation({
            mutationFn: () =>
                userApi.updateEmailSettings(
                    +newFrequency,
                    +newTimezone,
                    newTime,
                    +newDay
                ),
            onSuccess: (response) => {
                if (response && response.status == 1) {
                    handleFetchEmailSettings()
                }
            },
        })

    useEffect(() => {
        if (emailSettingsRes?.status == 1) {
            const { frequency, time, timezone, day_of_period } =
                emailSettingsRes.data!.settings
            setDefaultEmailSettings({
                frequency,
                time,
                timezone,
                day_of_period,
            })

            if (defaultEmailSettings?.day_of_period) {
                setNewDay(defaultEmailSettings.day_of_period.toString())
            }
        }
    }, [emailSettingsRes])

    // ** methods
    const handleOpenNewEmailModal = () => {
        setNewEmailModalOpen(true)
    }

    const handleCloseNewEmailModal = () => {
        setNewEmailModalOpen(false)
    }

    const handleChangeFrequency = (option: DropdownOption) => {
        setNewFrequency(option.value)
    }

    const handleSelectTimezone = (option: DropdownOption) => {
        setNewTimezone(option.value)
    }

    const handlePickTime = (option: DropdownOption) => {
        setNewTime(option.value)
    }

    const handleChangeDay = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setNewDay(target.value)
    }

    return (
        <div className='flex flex-col items-center flex-1 gap-y-5'>
            <Helmet>
                <title>Email Setting | Trending Title</title>
            </Helmet>

            {/* begin: user emails */}
            <section className='container flex flex-col self-center gap-y-2 px-5'>
                <div className='flex justify-between items-center'>
                    <h1 className='text-xl'> Emails </h1>

                    <Button onClick={handleOpenNewEmailModal}> + Add </Button>
                </div>
                <div className='flex gap-x-2 bg-white rounded shadow min-h-[10rem] px-4 py-3'>
                    {emailsLoading ? (
                        <Loading />
                    ) : (
                        emailsList?.data?.emails?.map((item) => (
                            <Chip key={item.email} label={item.email} />
                        ))
                    )}
                </div>
            </section>
            {/* end: user emails */}

            {/* begin: general email settings */}
            <section className='container flex flex-col gap-y-2 px-5'>
                <div className='flex items-center justify-between'>
                    <h1 className='text-xl'> General Email Settings </h1>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-3 bg-white rounded shadow gap-3 px-4 py-3'>
                    {emailSettingsLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <Select
                                label='Frequency'
                                options={[
                                    { title: 'daily', value: '1' },
                                    { title: 'weekly', value: '2' },
                                    { title: 'monthly', value: '3' },
                                ]}
                                defaultValue={defaultEmailSettings?.frequency?.toString()}
                                placeholder='Select an item'
                                value={newFrequency}
                                onChange={handleChangeFrequency}
                            />

                            <Select
                                label='Timezone'
                                autocomplete
                                defaultValue={defaultEmailSettings?.timezone?.id?.toString()}
                                placeholder='Select a timezone'
                                loading={timezonesLoading}
                                value={newTimezone}
                                options={
                                    timezones?.data?.timezones?.map((item) => ({
                                        title: `${item.name} - ${item.text}`,
                                        value: item.id.toString(),
                                    })) || []
                                }
                                onChange={handleSelectTimezone}
                            />
                            <Select
                                label='Time'
                                options={Array.from(Array(24), (n, i) => i).map(
                                    (item) => ({
                                        title: `${
                                            item >= 10 ? item : '0' + item
                                        }:00`,
                                        value: `${
                                            item >= 10 ? item : '0' + item
                                        }:00`,
                                    })
                                )}
                                {...(defaultEmailSettings?.time && {
                                    defaultValue: defaultEmailSettings.time
                                        .split(':')
                                        .slice(0, -1)
                                        .join(':'),
                                })}
                                placeholder='Select a new time'
                                value={newTime}
                                onChange={handlePickTime}
                            />

                            {+newFrequency !== 1 ? (
                                <Input
                                    label={`Day of ${
                                        +newFrequency == 2 ? 'week' : 'month'
                                    }`}
                                    placeholder={`Enter a number for day of ${
                                        +newFrequency == 2 ? 'week' : 'month'
                                    }`}
                                    maxLength={2}
                                    max={31}
                                    value={
                                        newDay ||
                                        defaultEmailSettings?.day_of_period?.toString()
                                    }
                                    onChange={handleChangeDay}
                                />
                            ) : null}
                        </>
                    )}
                </div>
                {!emailSettingsLoading && (
                    <div className='flex items-center justify-end gap-x-2'>
                        <Button
                            loading={updateSettingsLoading}
                            onClick={() => handleUpdateSettings()}
                        >
                            {' '}
                            Save{' '}
                        </Button>
                        <Button variant='outlined'> Reset </Button>
                    </div>
                )}
            </section>
            {/* end: general email settings */}

            {/* begin: New email modal */}
            <Modal
                open={newEmailModalOpen}
                dismissable={!newEmailLoading}
                onClose={handleCloseNewEmailModal}
                contentContainerClass='flex flex-col gap-y-10 p-5'
            >
                <Input
                    type='email'
                    placeholder='Enter new email address'
                    value={newEmail}
                    onChange={({ target }) => setNewEmail(target.value)}
                />

                <div className='flex justify-end items-center gap-x-2'>
                    <Button
                        loading={newEmailLoading}
                        color='primary'
                        onClick={() => handleAddNewEmail()}
                    >
                        {' '}
                        Add{' '}
                    </Button>
                    <Button
                        variant='outlined'
                        onClick={handleCloseNewEmailModal}
                    >
                        {' '}
                        Dismiss{' '}
                    </Button>
                </div>
            </Modal>
            {/* end: New email modal */}
        </div>
    )
}

export default EmailSetting
