// ** react imports
import { ChangeEvent, ReactNode, useEffect, useState } from 'react'

// ** custom components imports
import Input, { InputProps } from './Input'
import Loading from './Loading'
import Icon from './Icon'

// ** hooks imports
import { useClickAway } from '../hooks'

export type DropdownOption = {
    title: string
    value: string
    icon?: ReactNode
}

type RequiredInputProps = Omit<
    InputProps,
    | 'onChange'
    | 'ref'
    | 'type'
    | 'autoComplete'
    | 'autoCorrect'
    | 'defaultChecked'
>

export interface SelectProps extends RequiredInputProps {
    options: Array<DropdownOption>
    autocomplete?: boolean
    loading?: boolean
    defaultValue?: string

    // styling
    containerClassName?: string

    // methods
    onChange: (option: DropdownOption) => void
}

const Select = ({
    options,
    loading,
    autocomplete,
    containerClassName,
    defaultValue = '',
    onChange,
    ...props
}: SelectProps) => {
    // ** state
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [value, setValue] = useState(
        defaultValue
            ? options.filter((item) => item.value === defaultValue)?.at(0)
                  ?.title || ''
            : ''
    )

    // ** hooks
    const ref = useClickAway(() => {
        setDropdownOpen(false)
    })

    useEffect(() => {
        if (defaultValue) {
            const option = options
                .filter((item) => item.value === defaultValue)
                ?.at(0)

            setValue(option?.title || '')
            !!option && onChange(option)
        }
    }, [defaultValue, options, onChange])

    // ** methods
    const handleOpenDropdown = () => {
        setDropdownOpen(true)
    }

    const handleSelectOption = (option: DropdownOption) => () => {
        onChange(option)
        setValue(option.title)
        setDropdownOpen(false)
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }

    return (
        <div ref={ref} className={`relative ${containerClassName || ''}`}>
            <Input
                readOnly={!autocomplete}
                {...props}
                value={value}
                endIcon={
                    <div className='w-7'>
                        {loading ? (
                            <Loading />
                        ) : (
                            <Icon
                                icon={
                                    dropdownOpen
                                        ? 'majesticons:chevron-up'
                                        : 'majesticons:chevron-down'
                                }
                                className='text-gray-500'
                            />
                        )}
                    </div>
                }
                onChange={handleChange}
                onClick={handleOpenDropdown}
            />

            <ul
                className={`flex-col absolute top-full left-0 bg-white shadow-lg w-full rounded max-h-[13rem] overflow-y-auto p-2 z-20 ${
                    dropdownOpen ? 'flex' : 'hidden'
                }`}
            >
                {options
                    .filter((opt) =>
                        autocomplete ? opt.title.includes(value) : opt
                    )
                    .map((opt) => (
                        <li
                            key={opt.value}
                            className='hover:bg-gray-100 hover:cursor-pointer rounded text-sm px-2 py-1'
                            onClick={handleSelectOption(opt)}
                        >
                            {opt.title}
                        </li>
                    ))}
            </ul>
        </div>
    )
}

export default Select
