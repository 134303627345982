// ** react imports
import { useState } from 'react'

// ** react router imports
import { Link } from 'react-router-dom'

// ** layouts imports
import ForgetPasswordStep from '../../layouts/auth/ForgetPassword'
import ConfirmForgetPasswordStep from '../../layouts/auth/ConfirmForgetPassword'
import ChangePassword from '../../layouts/auth/ChangePassword'

// ** custom component imports
import Button from '../../components/Button'

// ** third party imports
import { Helmet } from 'react-helmet-async'

const ForgetPassword = () => {
    // ** states
    const [step, setStep] = useState(1)
    const [username, setUsername] = useState('')

    // ** methods
    const handleForgetPasswordStepChange = (username: string) => {
        setStep(2)
        setUsername(username)
    }

    const handleConfirmForgetPasswordStepChange = () => {
        setStep(3)
    }

    return (
        <div>
            <Helmet>
                <title>Forget Password | Trending Title</title>
            </Helmet>
            <h1 className='text-2xl text-center text-indigo-900'>
                Welcome to <span className='font-bold'>Trending Title</span>
            </h1>

            <div className='my-4'>
                {step === 1 && (
                    <ForgetPasswordStep
                        onNextStep={handleForgetPasswordStepChange}
                    />
                )}
                {step === 2 && (
                    <ConfirmForgetPasswordStep
                        username={username}
                        onNextStep={handleConfirmForgetPasswordStepChange}
                    />
                )}
                {step === 3 && <ChangePassword username={username} />}
            </div>

            <Link to='/auth/signin' className='w-full'>
                <Button
                    type='submit'
                    variant='text'
                    className='shadow-none border-none text-violet-600 hover:bg-opacity-80 w-full'
                    tabIndex={4}
                >
                    Sign In
                </Button>
            </Link>
        </div>
    )
}

export default ForgetPassword
