// ** services imports
import { BaseService } from '../base/base.service'

// ** types imports
import { TimezonesListApiResponse } from './types'

export default class TimezoneService extends BaseService {
    private prefix = this.getBaseUrl.concat('timezones/')

    /**
     * Use to get all timezones list
     */
    public async timezones() {
        const url = this.prefix.slice(0, -1)

        return this.get<TimezonesListApiResponse>(url)
    }
}
