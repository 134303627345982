// ** react imports
import { createRef, useState } from 'react'

// ** react router imports
import { useNavigate } from 'react-router-dom'

// ** store imports
import { useAppDispatch } from '../../store/types'
import { updateUser } from '../../store/featues/user'

// ** custom components imports
import Input from '../../components/Input'
import Button from '../../components/Button'

// ** third party imports
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useMutation } from '@tanstack/react-query'
import Recaptcha from 'react-google-recaptcha'
import { useCountdown } from 'usehooks-ts'

// ** services imports
import AuthService from '../../services/auth/auth.service'
import { SignupConfirmParams } from '../../services/auth/types'

// ** configs imports
import authConfig from '../../configs/auth.config'

// ** utils imports
import { toaster } from '../../utils'

const schema = yup.object().shape({
    email: yup
        .string()
        .email('Email is not valid')
        .required('Please enter your email'),
    code: yup
        .string()
        .length(8, 'Verification code is not valid')
        .required('Please enter email verification code'),
})

const defaultValues = {
    email: '',
    code: '',
}

type FormData = {
    email: string
    code: string
}

const authApi = new AuthService()

interface ForgetPasswordProps {
    username: string
}

const SignUpConfirm = (props: ForgetPasswordProps) => {
    // ** vars
    const recaptchaRef = createRef<Recaptcha>()

    // ** states
    const [recaptchaToken, setRecaptchaToken] = useState('')

    // ** hooks
    const dispatch = useAppDispatch()
    const [timer, { startCountdown, resetCountdown }] = useCountdown({
        countStart: 180,
        intervalMs: 1000,
    })
    const navigate = useNavigate()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            ...defaultValues,
            email: props.username,
        },
        mode: 'onBlur',
        resolver: yupResolver(schema),
    })

    const { isPending: forgetPasswordLoading, mutate: handleConfirmSignUp } =
        useMutation({
            mutationFn: ({ email, code }: Omit<SignupConfirmParams, 'token'>) =>
                authApi.signupConfirm({ email, code, token: recaptchaToken }),
            onSuccess: (response) => {
                if (response?.status === 1) {
                    const { token, ...info } = response.data!
                    localStorage.setItem(authConfig.tokenKey, token)
                    localStorage.setItem(authConfig.info, JSON.stringify(info))
                    dispatch(updateUser({ ...response.data }))
                    navigate('/topic-selection')
                    toaster('Logged in successfully', {
                        severity: 'success',
                    })
                }

                toaster(response?.message || 'Error occured', {
                    severity: 'error',
                })
            },
        })

    const { isPending: resendCodeLoading, mutate: handleRequestResendCode } =
        useMutation({
            mutationFn: () =>
                authApi.resendSignupCode({
                    email: props.username,
                    token: recaptchaToken,
                }),
            onSettled: () => {
                // reset recaptcha
                recaptchaRef.current?.reset()
            },
            onSuccess: () => {
                if (timer === 0) resetCountdown()

                startCountdown()
            },
        })

    // ** methods
    const handleResendCode = () => {
        if (!recaptchaRef.current?.getValue()) {
            toaster('Please check recaptcha first', { severity: 'error' })
            return
        }

        handleRequestResendCode()

        // reset recaptcha
        recaptchaRef.current?.reset()
    }

    const handleSignupConfirm = (params: FormData) => {
        if (!recaptchaRef.current?.getValue()) {
            toaster('Please check recaptcha first', { severity: 'error' })
            return
        }

        handleConfirmSignUp(params)

        // reset recaptcha
        recaptchaRef.current?.reset()
    }

    return (
        <form
            className='flex flex-col gap-y-5 w-full'
            onSubmit={handleSubmit(handleSignupConfirm)}
        >
            <Input
                placeholder='Email'
                name='email'
                control={control}
                error={errors.email?.message}
                readOnly
                tabIndex={-1}
            />
            <Input
                placeholder='Verifiacation Code'
                name='code'
                control={control}
                error={errors.code?.message}
                maxLength={8}
                tabIndex={1}
                autoFocus
            />

            <div className='flex justify-center'>
                <Recaptcha
                    size='normal'
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                    onChange={(token) => {
                        if (token) setRecaptchaToken(token)
                    }}
                />
            </div>

            <div className='flex gap-x-2'>
                <Button
                    type='button'
                    loading={resendCodeLoading}
                    className='shadow-none border-none text-violet-600 !bg-gray-200 hover:bg-opacity-80 w-1/2 min-w-fit'
                    disabled={timer > 0 && timer < 10}
                    onClick={() => handleResendCode()}
                >
                    {timer > 0 && timer < 10
                        ? `${timer} ${timer > 1 ? 'seconds' : 'second'}`
                        : 'Resend Code'}
                </Button>
                <Button
                    type='submit'
                    loading={forgetPasswordLoading}
                    className='min-w-fit w-1/2'
                >
                    Verify Code
                </Button>
            </div>
        </form>
    )
}

export default SignUpConfirm
