'use client'

// ** react imports
import { createRef, useState } from 'react'

// ** custom components imports
import Input from '../../components/Input'
import Button from '../../components/Button'

// ** third party imports
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useMutation } from '@tanstack/react-query'
import Recaptcha from 'react-google-recaptcha'

// ** services imports
import AuthService from '../../services/auth/auth.service'

// ** types imports
import { SignupParams } from '../../services/auth/types'

// ** utils
import { toaster } from '../../utils'

const schema = yup.object().shape({
    first_name: yup.string().required('Please enter your first name'),
    last_name: yup.string().required('Please enter your last last'),
    email: yup
        .string()
        .email('Email is not valid')
        .required('Please enter your email'),
    password: yup
        .string()
        .min(8, 'Password length must be at least 8 characters')
        .required('Please enter your password'),
})

const defaultValues = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
}

type FormData = {
    first_name: string
    last_name: string
    email: string
    password: string
}

interface SignUpProps {
    onNextStep: (email: string) => void
}

const authApi = new AuthService()

const SignUp = (props: SignUpProps) => {
    // ** states
    const [recaptchaToken, setRecaptchaToken] = useState('')

    // ** hooks
    const recaptchaRef = createRef<Recaptcha>()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(schema),
    })

    const { isPending: signupLoading, mutate: handleSignUp } = useMutation({
        mutationFn: (params: SignupParams) =>
            authApi.signup({ ...params, token: recaptchaToken }),
        onSuccess: (data, params) => {
            // if (data.status === 1) props.onNextStep(params.email);
        },
    })

    const handleSignup = (data: FormData) => {
        if (!recaptchaRef.current?.getValue()) {
            toaster('Please check recaptcha first', { severity: 'error' })
            return
        }

        handleSignUp({
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            password: data.password,
        })

        // reset recaptcha
        recaptchaRef.current?.reset()
    }

    return (
        <form
            className='flex flex-col gap-y-3 w-full'
            onSubmit={handleSubmit(handleSignup)}
        >
            <Input
                placeholder='First Name'
                name='first_name'
                control={control}
                error={errors.first_name?.message}
                tabIndex={1}
                autoFocus
            />
            <Input
                placeholder='Last Name'
                name='last_name'
                error={errors.last_name?.message}
                control={control}
                tabIndex={2}
            />
            <Input
                type='email'
                placeholder='Email'
                name='email'
                control={control}
                error={errors.email?.message}
                tabIndex={3}
            />
            <Input
                type='password'
                placeholder='Passowrd'
                name='password'
                control={control}
                error={errors.password?.message}
                tabIndex={4}
            />

            <div className='flex justify-center'>
                <Recaptcha
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                    onChange={(token) => {
                        if (token) setRecaptchaToken(token)
                    }}
                />
            </div>

            <Button type='submit' loading={signupLoading}>
                Sign Up
            </Button>
        </form>
    )
}

export default SignUp
