// ** react imports
import { FC, useEffect, useState } from 'react'

// ** custom components imports
import { DataTable, DataTableColumnsProps } from '../../components'

// ** third party imports
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

// ** models imports
import { TitleHistoryModel } from '../../models'

// ** services imports
import UserService from '../../services/user/user.service'

type TitleHistory = Exclude<TitleHistoryModel, 'id' | 'user'>

// ** vars
const userApi = new UserService()

const columns: Array<DataTableColumnsProps> = [
    {
        field: 'row',
        headerName: '#',
        width: 50,
    },
    {
        field: 'interest',
        headerName: 'Interest',
    },
    {
        field: 'country',
        headerName: 'Country',
    },
    {
        field: 'time',
        headerName: 'Time',
    },
    {
        field: 'timezone',
        headerName: 'Timezone',
    },
    {
        field: 'title',
        headerName: 'Title',
        flex: 1,
        fotmatter: (value) => value || '---',
    },
]

const HistoryScreen: FC = () => {
    // ** states
    const [titles, setTitles] = useState<Array<TitleHistory>>([])
    const [paginationParams, setPaginationParams] = useState({ page: 0, pageSize: 10 })

    // ** hooks
    const { data: titlesHistoryRes } = useQuery({
        queryKey: ['titles_history_list'],
        queryFn: () => userApi.titlesHistory(),
    })

    useEffect(() => {
        if (titlesHistoryRes?.status === 1) {
            setTitles(titlesHistoryRes.data?.history || [])
        }
    }, [titlesHistoryRes])

    return (
        <div className='flex flex-col gap-y-5 flex-1'>
            <Helmet>
                <title>History | Trending Title</title>
            </Helmet>

            {/* BEGIN: titles history */}
            <section className='container flex flex-col self-center gap-y-2'>
                <div className='flex justify-between items-center'>
                    <h1 className='text-xl'>Generated Titles History</h1>
                </div>
                <div className='flex gap-x-2 bg-white rounded shadow min-h-[10rem] px-4 py-3'>
                    <div className='flex flex-col border border-gray-300 rounded flex-1'>
                        <DataTable
                            columns={columns}
                            rows={titles.map((item, i) => ({
                                row: i + 1,
                                ...item,
                            }))}
                        />
                    </div>
                </div>
            </section>
            {/* END: titles history */}
        </div>
    )
}

export default HistoryScreen
