// ** services imports
import { BaseService } from '../base/base.service'

// ** types
import { CountriesListApiResponse, UserCountriesListApiResponse } from './types'

export default class CountriesService extends BaseService {
    private readonly prefix = this.getBaseUrl.concat('countries/')

    /**
     * Use to get all countries list
     */
    public async countries() {
        const url = this.prefix.slice(0, -1)

        return await this.get<CountriesListApiResponse>(url)
    }

    /**
     * Use to get all user's countries list
     */
    public async userCountries() {
        const url = this.prefix.concat('user_countries')

        return await this.get<UserCountriesListApiResponse>(url)
    }

    /**
     * Use to setup new country for user
     */
    public async newUserCountry(countryId: string) {
        const url = this.prefix.concat('new_user_country')

        const reqBody = {
            country_id: countryId,
        }

        return await this.post(url, reqBody)
    }
}
