// ** react router imports
import { RouteObject } from 'react-router-dom'

// ** views imports
import TopicSelection from '../views/panel/TopicSelection'
import Billing from '../views/panel/Billing'
import Demo from '../views/panel/Demo'
import EmailSetting from '../views/panel/EmailSetting'
import HistoryScreen from '../views/panel/History'
import Settings from '../views/panel/Settings'

const panelRoutes: Array<RouteObject> = [
    {
        path: 'topic-selection',
        element: <TopicSelection />,
    },
    {
        path: 'demo',
        element: <Demo />,
    },
    {
        path: 'email-setting',
        element: <EmailSetting />,
    },
    {
        path: 'history',
        element: <HistoryScreen />,
    },
    {
        path: 'settings',
        element: <Settings />,
    },
    {
        path: 'billing',
        element: <Billing />,
    },
]

export default panelRoutes
