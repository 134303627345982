// ** react imports
import { Fragment, ReactNode, useEffect } from 'react'

// ** next imports
import { useNavigate, useLocation } from 'react-router-dom'

// ** store imports
import { AppDispatch, RootState } from '../store/types'
import { updateUser } from '../store/featues/user'

// ** third party imports
import { useSelector, useDispatch } from 'react-redux'

// ** models
import { UserModel } from '../models'

// ** configs
import authConfig from '../configs/auth.config'

interface AuthGuardProps {
    children: ReactNode
}

const AuthGuard = ({ children }: AuthGuardProps) => {
    // ** hooks
    const { user } = useSelector((selector: RootState) => selector.user)
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    // check user authorization on every route change
    useEffect(() => {
        handleUserAuthorization()
    }, [pathname])

    // ** methods
    const handleUserAuthorization = () => {
        const token = window.localStorage.getItem(authConfig.tokenKey)

        if (user === null && !token) {
            if (pathname !== '/') {
                navigate('/auth/signin', {
                    replace: true,
                    state: { returnUrl: pathname },
                })

                return
            }

            navigate('/auth/signin', { replace: true })
        } else if (token) {
            const stringifiedUser = window.localStorage.getItem(authConfig.info)
            const user: Omit<UserModel, 'token'> = JSON.parse(
                stringifiedUser as string
            )
            dispatch(updateUser({ ...user }))
        }
    }

    return <Fragment>{children}</Fragment>
}

export default AuthGuard
