// ** third party imports
import { Helmet } from 'react-helmet-async'

const Billing = () => {
    return (
        <div className='flex flex-col flex-1'>
            <Helmet>
                <title>Billing | Trending Title</title>
            </Helmet>

            <h1>coming soon...</h1>
        </div>
    )
}

export default Billing
