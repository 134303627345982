// ** third party imports
import {
    Icon as IconifyIcon,
    IconProps as DefaultIconifyProps,
} from '@iconify/react'

export interface IconProps extends DefaultIconifyProps {}

const Icon = (props: IconProps) => {
    return <IconifyIcon fontSize='1.5rem' {...props} />
}

export default Icon
