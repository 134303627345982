// ** react imports
import React from 'react'

// ** react dom imports
import ReactDOM from 'react-dom/client'

// ** redux imports
import { Provider } from 'react-redux'
import store from './store'

// ** resources imports
import App from './App'
import reportWebVitals from './reportWebVitals'

// ** third party imports
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { HelmetProvider } from 'react-helmet-async'

// ** styles imports
import './assets/styles/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const client = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
        mutations: {
            retry: false,
        },
    },
})

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={client}>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
