// ** react router imports
import { Outlet } from 'react-router-dom'

// ** layouts imports
import AppBar from './AppBar'
import NavigationBar from './NavigationBar'
import AuthGuard from './AuthGuard'

const PanelLayout = () => {
    return (
        <AuthGuard>
            <AppBar />
            <NavigationBar />

            <main className='flex flex-col flex-1 bg-gray-200 p-5'>
                <Outlet />
            </main>
        </AuthGuard>
    )
}

export default PanelLayout
