// ** redux imports
import { PayloadAction } from '@reduxjs/toolkit'

// ** models imports
import { UserModel } from '../../../models'

// ** types imports
import { UserInitialState } from './types'
import authConfig from '../../../configs/auth.config'

/**
 * Use to update the stored user
 */
export const update = (
    state: UserInitialState,
    { payload }: PayloadAction<Partial<UserModel>>
) => {
    state.user = {
        ...payload,
    }
}

/**
 * Use to logout the user
 */
export const logout = (state: UserInitialState, payload: PayloadAction) => {
    state.user = null
    localStorage.removeItem(authConfig.tokenKey)
    localStorage.removeItem(authConfig.info)
}
