// ** react imports
import { FC, useState } from 'react'

// ** custom components imports
import Select, { DropdownOption } from '../../components/Select'
import Button from '../../components/Button'

// ** third party iports
import { useMutation, useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

// ** services iports
import InterestsService from '../../services/interests/interests.service'
import CountriesService from '../../services/countries/countries.service'
import UserService from '../../services/user/user.service'

// ** utils imports
import { toaster } from '../../utils'

// ** vars
const interestApi = new InterestsService()
const countryApi = new CountriesService()
const userApi = new UserService()

const Demo: FC = () => {
    // ** states
    const [selectedInterest, setSelectedInterest] = useState('')
    const [selectedCountry, setSelectedCountry] = useState('')

    // ** hooks
    const { isLoading: interestsLoading, data: interests } = useQuery({
        queryKey: ['interests_list'],
        queryFn: () => interestApi.interests(),
    })

    const { isLoading: countriesLoading, data: countries } = useQuery({
        queryKey: ['countries_list'],
        queryFn: () => countryApi.countries(),
        refetchOnWindowFocus: false,
        retry: 0,
    })

    const {
        isPending: generateTitleLoading,
        mutate: handleGenerateDemoTitles,
        data: titles,
    } = useMutation({
        mutationFn: () =>
            userApi.generateDemoTitles(+selectedInterest, +selectedCountry),
        onSuccess: (response) => {
            if (response?.status === 1) {
            }
        },
    })

    // ** methods
    const handleChangeInterest = (option: DropdownOption) => {
        setSelectedInterest(option.value)
    }

    const handleChangeCountry = (option: DropdownOption) => {
        setSelectedCountry(option.value)
    }

    const handleCheckBeforeGenerate = () => {
        if (!selectedInterest) {
            toaster('Please select an interest', { severity: 'error' })

            return
        }

        handleGenerateDemoTitles()
    }

    return (
        <div className='flex flex-col flex-1 gap-y-5'>
            <Helmet>
                <title>Demo | Trending Title</title>
            </Helmet>

            <div className='grid grid-cols-3 lg:grid-cols-5 items-end gap-3 bg-white shadow rounded px-4 py-3'>
                <Select
                    label='Topic'
                    options={
                        interests?.data?.interests?.map((item) => ({
                            title: item.name,
                            value: item.id.toString(),
                        })) || []
                    }
                    placeholder='Select a topic'
                    containerClassName='col-span-2'
                    loading={interestsLoading}
                    value={selectedInterest}
                    onChange={handleChangeInterest}
                />
                <Select
                    label='Country'
                    options={
                        countries?.data?.countries?.map((item) => ({
                            title: item.name,
                            value: item.id.toString(),
                        })) || []
                    }
                    placeholder='Select a country'
                    containerClassName='col-span-2'
                    loading={countriesLoading}
                    value={selectedCountry}
                    onChange={handleChangeCountry}
                />

                <Button
                    loading={generateTitleLoading}
                    className='py-3.5'
                    onClick={handleCheckBeforeGenerate}
                >
                    {' '}
                    Generate{' '}
                </Button>
            </div>

            {titles?.data?.titles && titles?.data?.titles.length > 0 && (
                <div className='flex flex-col gap-y-5 bg-white shadow rounded px-4 py-3'>
                    <h1> Generated Titles </h1>

                    <ul className='flex flex-col gap-y-3 lg:indent-5'>
                        {titles!.data!.titles.map((title, index) => (
                            <p
                                key={`generated-title-${index}`}
                                className='text-sm'
                            >
                                {index + 1}. {title}{' '}
                            </p>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default Demo
