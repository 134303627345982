// ** react imports
import { createRef, useState } from 'react'

// ** custom components imports
import Input from '../../components/Input'
import Button from '../../components/Button'

// ** third party imports
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useMutation } from '@tanstack/react-query'
import Recaptcha from 'react-google-recaptcha'
import { useCountdown } from 'usehooks-ts'

// ** services imports
import AuthService from '../../services/auth/auth.service'

// ** types imports
import { ConfirmForgetPasswordParams } from '../../services/auth/types'

// ** utils imports
import { toaster } from '../../utils'

const schema = yup.object().shape({
    username: yup
        .string()
        .email('Email is not valid')
        .required('Please enter your email'),
    code: yup
        .string()
        .length(8, 'Verification code is not valid')
        .required('Please enter email verification code'),
})

const defaultValues = {
    username: '',
    code: '',
}

type FormData = {
    username: string
    code: string
}

const authApi = new AuthService()

interface ForgetPasswordProps {
    username: string
    onNextStep: () => void
}

const ConfirmForgetPassword = (props: ForgetPasswordProps) => {
    // ** vars
    const recaptchaRef = createRef<Recaptcha>()

    // ** states
    const [recaptchaToken, setRecaptchaToken] = useState('')

    // ** hooks
    const [timer, { startCountdown, resetCountdown }] = useCountdown({
        countStart: 180,
        intervalMs: 1000,
    })
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            ...defaultValues,
            username: props.username,
        },
        mode: 'onBlur',
        resolver: yupResolver(schema),
    })

    const {
        isPending: forgetPasswordLoading,
        mutate: handleRequestConfirmForgetPassword,
    } = useMutation({
        mutationFn: ({ username, code }: ConfirmForgetPasswordParams) =>
            authApi.confirmForgetPassword({
                username,
                code,
                token: recaptchaToken,
            }),
        onSuccess: (response) => {
            // if (response.status === 1) return props.onNextStep();
            // toaster(response?.message || 'Error occured', {
            //     severity: 'error',
            // });
        },
    })

    const { isPending: resendCodeLoading, mutate: handleRequestResendCode } =
        useMutation({
            mutationFn: () =>
                authApi.resendForgetPassCode({
                    email: props.username,
                    token: recaptchaToken,
                }),
            onSettled: () => {
                // reset recaptcha
                recaptchaRef.current?.reset()
            },
            onSuccess: () => {
                if (timer === 0) resetCountdown()

                startCountdown()
            },
        })

    // ** methods
    const handleResendCode = () => {
        if (!recaptchaRef.current?.getValue()) {
            toaster('Please check recaptcha first', { severity: 'error' })
            return
        }

        handleRequestResendCode()

        // reset recaptcha
        recaptchaRef.current?.reset()
    }

    const handleConfirmForgetPassword = (params: FormData) => {
        if (!recaptchaRef.current?.getValue()) {
            toaster('Please check recaptcha first', { severity: 'error' })
            return
        }

        handleRequestConfirmForgetPassword(params)

        // reset recaptcha
        recaptchaRef.current?.reset()
    }

    return (
        <form
            className='flex flex-col gap-y-5 w-full'
            onSubmit={handleSubmit(handleConfirmForgetPassword)}
        >
            <Input
                placeholder='Email'
                name='username'
                control={control}
                error={errors.username?.message}
                readOnly
            />
            <Input
                placeholder='Verifiacation Code'
                name='code'
                control={control}
                error={errors.code?.message}
                maxLength={8}
                tabIndex={1}
                autoFocus
            />

            <div className='flex justify-center'>
                <Recaptcha
                    size='normal'
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                    tabindex={2}
                    onChange={(token) => {
                        if (token) setRecaptchaToken(token)
                    }}
                />
            </div>

            <div className='flex gap-x-2'>
                <Button
                    type='button'
                    loading={resendCodeLoading}
                    className='shadow-none border-none text-violet-600 !bg-gray-200 hover:bg-opacity-80 w-1/2 min-w-fit'
                    tabIndex={3}
                    disabled={timer > 0 && timer < 180}
                    onClick={() => handleResendCode()}
                >
                    {timer > 0 && timer < 180
                        ? `${timer} ${timer > 1 ? 'seconds' : 'second'}`
                        : 'Resend Code'}
                </Button>
                <Button
                    type='submit'
                    loading={forgetPasswordLoading}
                    className='min-w-fit w-1/2'
                    tabIndex={4}
                >
                    Verify Code
                </Button>
            </div>
        </form>
    )
}

export default ConfirmForgetPassword
