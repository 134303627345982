// ** base service imports
import { BaseService } from '../base/base.service'

// ** types imports
import {
    type ChangePasswordParams,
    type ConfirmForgetPasswordParams,
    type ForgetPasswordParams,
    type ResendCodeParams,
    type SigninParams,
    type SignupConfirmParams,
    type SignupParams,
    ChangePasswordApiResponse,
    SignUpConfirmApiResponse,
    SigninApiResponse,
} from './types'

export default class AuthService extends BaseService {
    private readonly prefix = this.getBaseUrl.concat('auth/')

    /**
     * Use to signin the user
     */
    public async signin({ username, password, token }: SigninParams) {
        const url = this.prefix.concat('signin')

        const headers = {
            token,
        }

        const reqBody = new FormData()
        reqBody.append('email', username)
        reqBody.append('password', password)

        return await this.post<SigninApiResponse>(url, reqBody, headers)
    }

    /**
     * Use to signup the user
     */
    public async signup({
        firstName,
        lastName,
        email,
        password,
        token,
    }: SignupParams) {
        const url = this.prefix.concat('signup')

        const headers = {
            token,
        }

        const reqBody = new FormData()
        reqBody.append('first_name', firstName)
        reqBody.append('last_name', lastName)
        reqBody.append('email', email)
        reqBody.append('password', password)

        return await this.post(url, reqBody, headers)
    }

    /**
     * Use to confirm signup
     */
    public async signupConfirm({ email, code, token }: SignupConfirmParams) {
        const url = this.prefix.concat('signup_confirm')

        const headers = {
            token,
        }

        const reqBody = {
            email: email,
            confirm_code: code,
        }

        return await this.post<SignUpConfirmApiResponse>(url, reqBody, headers)
    }

    /**
     * Use to request forget password
     */
    public async forgetPassword({ username, token }: ForgetPasswordParams) {
        const url = this.prefix.concat('forget_password')

        const headers = {
            token,
        }

        const reqBody = {
            email: username,
        }

        return await this.post(url, reqBody, headers)
    }

    /**
     * Use to confirm forget password
     */
    public async confirmForgetPassword({
        token,
        username,
        code,
    }: ConfirmForgetPasswordParams) {
        const url = this.prefix.concat('confirm_forget_password')

        const headers = {
            token,
        }

        const reqBody = {
            email: username,
            code,
        }

        return await this.post(url, reqBody, headers)
    }

    /**
     * Use to change user's password
     */
    public async changePassword({ token, ...data }: ChangePasswordParams) {
        const url = this.prefix.concat('change_password')

        const headers = {
            token,
        }

        const reqBody = data

        return await this.post<ChangePasswordApiResponse>(url, reqBody, headers)
    }

    /**
     * Use to resend sign up verification codes
     */
    public async resendSignupCode({ email, token }: ResendCodeParams) {
        const url = this.prefix.concat('resend_signup_code')

        const headers = { token }

        const reqBody = { email }

        return await this.post(url, reqBody, headers)
    }

    /**
     * Use to resend forget password verification codes
     */
    public async resendForgetPassCode({ email, token }: ResendCodeParams) {
        const url = this.prefix.concat('resend_forget_pass_code')

        const headers = { token }

        const reqBody = { email }

        return await this.post(url, reqBody, headers)
    }
}
