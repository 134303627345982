type SnackSeverity = 'info' | 'success' | 'warning' | 'error'

export interface SnackProps {
    message: string
    severity?: SnackSeverity

    // styling
    containerClassName?: string

    // methods
    onDismiss: () => void
}

const Snack = ({ message, severity = 'info', ...props }: SnackProps) => {
    const renderClass = () => {
        const classes = props.containerClassName?.split(' ') || []

        if (severity === 'info') classes.push('bg-sky-500 text-white')

        if (severity === 'success') classes.push('bg-green-500 text-white')

        if (severity === 'warning') classes.push('bg-yellow-500 text-white')

        if (severity === 'error') classes.push('bg-red-500 text-white')

        return classes.join(' ')
    }

    return (
        <div
            className={`flex items-center gap-x-5 rounded shadow-md max-w-full md:max-w-2xl px-5 py-3 ${renderClass()}`}
        >
            <p className='w-max'>{message}</p>

            <button
                type='button'
                className='rounded hover:bg-gray-100/50 w-5 h-5'
                onClick={props.onDismiss}
            >
                <i className='bx bx-x leading-[1.3rem] text-lg h-full w-full'></i>
            </button>
        </div>
    )
}

export default Snack
