// ** services imports
import { BaseService } from '../base/base.service'

// ** types
import { InterestsListApiResponse, UserInterestsApiResponse } from './types'

export default class InterestsService extends BaseService {
    private readonly prefix = this.getBaseUrl.concat('interests/')

    /**
     * Use to get all interests list
     */
    public async interests() {
        const url = this.prefix.slice(0, -1)

        return await this.get<InterestsListApiResponse>(url)
    }

    /**
     * Use to get all user's interests list
     */
    public async userInterests() {
        const url = this.prefix.concat('user_interests')

        return await this.get<UserInterestsApiResponse>(url)
    }

    /**
     * Use to add new interest to user interests
     *
     * @param id interest id
     */
    public async newUserInterst(id: string) {
        const url = this.prefix.concat('new_user_interest')

        const reqBody = {
            interest_id: id,
        }

        return await this.post(url, reqBody)
    }
}
