// ** react imports
import { CSSProperties, useCallback } from 'react'

type AvailableColumnCSSProperties = Pick<
    CSSProperties,
    'flex' | 'width' | 'minWidth' | 'maxWidth'
>

export interface DataTableColumnsProps extends AvailableColumnCSSProperties {
    field: string
    headerName: string
    fotmatter?: (value: string) => string
}

export interface DataTableProps {
    columns: Array<DataTableColumnsProps>
    rows: Array<any>
}

export const DataTable = ({ columns, rows, ...props }: DataTableProps) => {
    // ** methods
    const renderHeaders = (col: DataTableColumnsProps) => {
        const { field, headerName, ...headerStyles } = col!

        return (
            <th key={field} className='py-4' style={{ ...headerStyles }}>
                {headerName}
            </th>
        )
    }

    const renderRow = useCallback(
        (row: any) => {
            const headers = Object.values(columns)
            const headerNames = Object.values(columns).map((col) => col.field)

            return (
                <tr className='text-center'>
                    {headerNames.map((header, index) =>
                        renderCell(
                            headers.at(index)?.fotmatter?.(row[header]) ||
                                row[header]
                        )
                    )}
                </tr>
            )
        },
        [rows, columns]
    )

    const renderCell = (cell: string) => <td>{cell}</td>

    return (
        <div className='container self-center'>
            <table className='w-full'>
                <thead className='bg-violet-600 text-white'>
                    <tr>{columns.map(renderHeaders)}</tr>
                </thead>
                <tbody>{rows.map(renderRow)}</tbody>
            </table>
        </div>
    )
}
