// ** store imports
import store from '../../store'
import { logoutUser } from '../../store/featues/user'

// ** third party imports
import axios, { AxiosError, AxiosResponse } from 'axios'

// ** configs imports
import authConfig from '../../configs/auth.config'

// ** types imports
import { BaseApiResponseModel } from '../types'

// ** utils imports
import { toaster } from '../../utils'

export class BaseService {
    private baseUrl = process.env.REACT_APP_API_BASE_URL as string

    get getBaseUrl(): string {
        return this.baseUrl
    }

    set setBaseUrl(newUrl: string) {
        this.baseUrl = newUrl
    }

    private _requestHeaderGenerator(newHeaders?: object): object {
        const _headers = {
            'Content-Type': 'application/json',
        }

        // set token in headers if exist
        const token = localStorage.getItem(authConfig.tokenKey)
        if (!!token) {
            Object.assign(_headers, { authorization: `Bearer ${token}` })
        }

        Object.assign(_headers, newHeaders)

        return _headers
    }

    /**
     * Use to log the user out
     */
    private logoutUserFromPanel(): void {
        store.dispatch(logoutUser())
    }

    /**
     *
     * @param url
     * @param extraHeaders
     */
    public async get<T = {}>(
        url: string,
        extraHeaders?: object
    ): Promise<BaseApiResponseModel<T> | null> {
        const headers = this._requestHeaderGenerator(extraHeaders)

        try {
            const response: AxiosResponse<BaseApiResponseModel<T>> =
                await axios.get(url, {
                    headers,
                })

            const { status, code } = response.data

            if (status === 1) {
                return response.data
            } else {
                toaster(code.toString(), { severity: 'error' })
            }

            return null
        } catch (err: any) {
            if (err?.response) {
                const { status: httpStatus }: AxiosError = err.response
                const reqErr = err.response.data
                const errMsg = reqErr?.code

                if (httpStatus === 401) {
                    this.logoutUserFromPanel()

                    toaster(errMsg || 'Please log in to your account', {
                        severity: 'error',
                    })
                } else {
                    toaster(errMsg, { severity: 'error' })
                }
            } else {
                const message = err?.message || 'An error occurred'

                toaster(message, { severity: 'error' })
            }

            return null
        }
    }

    /**
     *
     * @param url
     * @param data
     * @param extraHeaders
     */
    public async post<T = {}>(
        url: string,
        data: any,
        extraHeaders?: object
    ): Promise<BaseApiResponseModel<T> | null> {
        const headers = this._requestHeaderGenerator(extraHeaders)

        try {
            const response: AxiosResponse<BaseApiResponseModel<T>> =
                await axios.post(url, data, {
                    headers,
                })

            const { status, code } = response.data

            if (status === 1) {
                return response.data
            } else {
                toaster(code.toString(), { severity: 'error' })
            }

            return null
        } catch (err: any) {
            if (err?.response) {
                const { status: httpStatus }: AxiosError = err.response
                const reqErr = err.response.data
                const errMsg = reqErr?.code

                if (httpStatus === 401) {
                    this.logoutUserFromPanel()

                    toaster(errMsg || 'Please log in to your account', {
                        severity: 'error',
                    })
                } else {
                    toaster(errMsg, { severity: 'error' })
                }
            } else {
                const message = err?.message || 'An error occurred'

                toaster(message, { severity: 'error' })
            }

            return null
        }
    }
}
