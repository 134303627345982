// ** react imports
import { PropsWithChildren } from 'react'

// ** react router imports
import { useLocation, Link, LinkProps } from 'react-router-dom'

interface AcitveLinkProps extends PropsWithChildren<LinkProps> {}

const ActiveLink = ({ to, children, ...props }: AcitveLinkProps) => {
    // ** hooks
    const { pathname } = useLocation()

    // ** methods
    const renderStyles = () => {
        const defaultClasses = ['rounded', 'text-lg', 'py-2', 'px-5']
        let classes = props?.className?.split(' ') || []

        classes = classes.concat(defaultClasses)

        if (pathname.includes(to.toString()))
            classes.push('bg-violet-600', 'text-white')
        else classes.push('hover:bg-gray-200')

        return classes.join(' ')
    }

    return (
        <Link to={to} className={renderStyles()} {...props}>
            {children}
        </Link>
    )
}

export default ActiveLink
