// ** react imports
import { useState } from 'react'

// ** custom components imports
import Modal from '../../components/Modal'
import Select, { DropdownOption } from '../../components/Select'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Chip from '../../components/Chip'

// ** third party imports
import { useMutation, useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'

// ** services imports
import InterestsService from '../../services/interests/interests.service'
import CountriesService from '../../services/countries/countries.service'

// ** vars
const interestsApi = new InterestsService()
const countriesApi = new CountriesService()

const TopicSelection = () => {
    // ** states
    const [newInterestModalOpen, setNewInterestModalOpen] = useState(false)
    const [newCountryModalOpen, setNewCountryModalOpen] = useState(false)
    const [newInterest, setNewInterest] = useState('')
    const [newCountry, setNewCountry] = useState('')

    // ** hooks
    const {
        isLoading: userInterestsLoading,
        data: userInterests,
        refetch: handleFetchUserInterests,
    } = useQuery({
        queryKey: ['user_interests'],
        queryFn: () => interestsApi.userInterests(),
    })

    const { isLoading: interestsLoading, data: interests } = useQuery({
        queryKey: ['interests_list'],
        queryFn: () => interestsApi.interests(),
    })

    const { isLoading: countriesLoading, data: countries } = useQuery({
        queryKey: ['countries_list'],
        queryFn: () => countriesApi.countries(),
    })

    const {
        isLoading: userCountriesLoading,
        data: userCountries,
        refetch: handleFetchUserCountries,
    } = useQuery({
        queryKey: ['user_countries'],
        queryFn: () => countriesApi.userCountries(),
    })

    const {
        isPending: newUserCountryLoading,
        mutate: handleCreateNewUserCountry,
    } = useMutation({
        mutationFn: () => countriesApi.newUserCountry(newCountry),
        onSuccess: (response) => {
            if (response) {
                setNewCountryModalOpen(false)
                handleFetchUserCountries()
            }
        },
    })

    const {
        isPending: newUserInterestLoading,
        mutate: handleSetupNewUserInterest,
    } = useMutation({
        mutationFn: () => interestsApi.newUserInterst(newInterest),
        onSuccess: (data) => {
            if (data) {
                handleFetchUserInterests()
                handleCloseNewInterestModal()
            }
        },
    })

    // ** methods
    const handleSelectNewInterest = (option: DropdownOption) => {
        setNewInterest(option.value)
    }

    const handleOpenNewInterestModal = () => {
        setNewInterestModalOpen(true)
    }

    const handleCloseNewInterestModal = () => {
        setNewInterestModalOpen(false)
    }

    const handleSelectCountry = (option: DropdownOption) => {
        setNewCountry(option.value)
    }

    const handleOpenNewCountryModal = () => {
        setNewCountryModalOpen(true)
    }

    const handleCloseNewCountryModal = () => {
        setNewCountryModalOpen(false)
    }

    return (
        <div className='flex flex-col gap-y-5 flex-1'>
            <Helmet>
                <title>Topic Selection | Trending Title</title>
            </Helmet>

            {/* begin: interests */}
            <section className='container flex flex-col self-center gap-y-2'>
                <div className='flex justify-between items-center'>
                    <h1 className='text-xl'> Interests </h1>

                    <Button onClick={handleOpenNewInterestModal}>+ Add</Button>
                </div>
                <div className='flex gap-x-2 bg-white rounded shadow min-h-[10rem] px-4 py-3'>
                    {userInterestsLoading ? (
                        <Loading />
                    ) : (
                        userInterests?.data?.interests.map((item, i) => (
                            <Chip
                                key={`${item}-{i}`}
                                label={item.interest.name}
                            />
                        ))
                    )}
                </div>
            </section>
            {/* end: interests */}

            {/* begin: countries */}
            <section className='container flex flex-col self-center gap-y-2'>
                <div className='flex justify-between items-center'>
                    <h1 className='text-xl'> Countries </h1>

                    <Button onClick={handleOpenNewCountryModal}> + Add </Button>
                </div>
                <div className='flex gap-x-2 bg-white rounded shadow min-h-[10rem] px-4 py-3'>
                    {userCountriesLoading ? (
                        <Loading />
                    ) : (
                        userCountries?.data?.countries.map((item, i) => (
                            <Chip
                                key={item.country.name}
                                label={item.country.name}
                            />
                        ))
                    )}
                </div>
            </section>
            {/* end: countries */}

            {/* begin: new interest modal */}
            <Modal
                open={newInterestModalOpen}
                dismissable={!newUserInterestLoading}
                onClose={handleCloseNewInterestModal}
                contentContainerClass='flex flex-col gap-y-10 p-5'
            >
                <Select
                    placeholder='Select an interest'
                    options={
                        interests?.data?.interests?.map((item) => ({
                            title: item.name,
                            value: item.id.toString(),
                        })) || []
                    }
                    onChange={handleSelectNewInterest}
                />

                <div className='flex justify-end items-center gap-x-2'>
                    <Button
                        loading={newUserInterestLoading}
                        color='primary'
                        onClick={() => handleSetupNewUserInterest()}
                    >
                        {' '}
                        Add{' '}
                    </Button>
                    <Button
                        variant='outlined'
                        onClick={handleCloseNewInterestModal}
                    >
                        {' '}
                        Dismiss{' '}
                    </Button>
                </div>
            </Modal>
            {/* end: new interest modal */}

            {/* begin: new country modal */}
            <Modal
                open={newCountryModalOpen}
                dismissable={!newUserCountryLoading}
                onClose={handleCloseNewCountryModal}
                contentContainerClass='flex flex-col gap-y-10 p-5'
            >
                <Select
                    placeholder='Select a country'
                    options={
                        countries?.data?.countries?.map((item) => ({
                            title: item.name,
                            value: item.id.toString(),
                        })) || []
                    }
                    onChange={handleSelectCountry}
                />

                <div className='flex justify-end items-center gap-x-2'>
                    <Button
                        loading={newUserCountryLoading}
                        color='primary'
                        onClick={() => handleCreateNewUserCountry()}
                    >
                        {' '}
                        Add{' '}
                    </Button>
                    <Button
                        variant='outlined'
                        onClick={handleCloseNewCountryModal}
                    >
                        {' '}
                        Dismiss{' '}
                    </Button>
                </div>
            </Modal>
            {/* end: new country modal */}
        </div>
    )
}

export default TopicSelection
