// ** third party imports
import { Icon } from '@iconify/react'

export interface ChipProps {
    label: string
    dismissable?: boolean
}

const Chip = ({ label, ...props }: ChipProps) => {
    // ** methods
    const renderDismissIcon = <Icon icon='close' />

    return (
        <div className='rounded-3xl bg-violet-600/20 text-violet-600 hover:bg-violet-600 hover:text-white cursor-pointer h-fit leading-3 !px-1.5 !py-0.5'>
            <span className='text-xs'> {label} </span>

            {props.dismissable ? renderDismissIcon : null}
        </div>
    )
}

export default Chip
