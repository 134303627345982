// ** react imports
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'

type DefaultButtonProps = DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>

type ButtonVariant = 'contained' | 'outlined' | 'text'

type ButtonColor = 'primary' | 'secondary' | 'success' | 'error'

interface ButtonProps extends DefaultButtonProps {
    variant?: ButtonVariant
    color?: ButtonColor
    loading?: boolean
}

export const Button = ({
    children,
    loading,
    variant = 'contained',
    color = 'primary',
    ...props
}: ButtonProps) => {
    const getButtonTheme = (type: 'bg' | 'border' | 'text') => {
        let theColor = ''

        switch (color) {
            case 'primary':
                theColor = `${type}-violet-600`
                break
            case 'secondary':
                theColor = `${type}-indigo-900`
                break
            case 'success':
                theColor = `${type}-emerald-600`
                break
            case 'error':
                theColor = `${type}-red-600`
                break
        }

        return theColor
    }

    const renderClass = () => {
        let classes = props.className ? props.className?.split(', ') : []

        if (loading || props.disabled)
            classes.push('cursor-not-allowed opacity-70')

        if (variant === 'contained')
            classes.push('button-contained', getButtonTheme('bg'), 'text-white')

        if (variant === 'outlined')
            classes.push(
                'button-outlined',
                'bg-transparent',
                'border',
                getButtonTheme('border'),
                getButtonTheme('text'),
                `hover:${getButtonTheme('bg')}`,
                'hover:text-white'
            )

        if (variant !== 'text') classes.push('shadow')

        if (variant === 'text') classes.push('text-violet-600 font-semibold')

        return classes.join(' ')
    }

    return (
        <button
            type='button'
            {...props}
            disabled={loading || props.disabled}
            className={`border rounded-md text-center px-9 py-2 ${renderClass()}`}
        >
            {loading ? (
                <div className='border-2 animate-spin border-gray-400 border-t-white mx-auto rounded-full w-5 h-5'></div>
            ) : (
                children
            )}
        </button>
    )
}

export default Button
