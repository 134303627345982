// ** react router imports
import { RouterProvider } from 'react-router-dom'

// ** routes imports
import MainRoutes from './routes/main.routes'

const App = () => {
    return (
        <RouterProvider
            router={MainRoutes}
            future={{ v7_startTransition: true }}
        />
    )
}

export default App
