// ** react router imports
import { Navigate, createHashRouter } from 'react-router-dom'

// ** routes imports
import authRoutes from './auth.routes'
import panelRoutes from './panel.routes'

// ** layouts imports
import RootLayout from '../layouts/RootLayout'
import AuthLayout from '../layouts/AuthLayout'
import PanelLayout from '../layouts/PanelLayout'

const mainRoutes = createHashRouter([
    {
        id: 'root',
        path: '/',
        element: <RootLayout />,
        children: [
            {
                path: 'auth',
                element: <AuthLayout />,
                children: authRoutes,
            },
            {
                path: '/',
                element: <PanelLayout />,
                children: panelRoutes,
            },
            {
                id: 'default-route',
                index: true,
                element: <Navigate to='topic-selection' replace />,
            },
            {
                id: 'not-found-page',
                path: '*',
                element: <Navigate to='topic-selection' replace />,
            },
        ],
    },
])

export default mainRoutes
