// ** redux imports
import { createSlice } from '@reduxjs/toolkit'

// ** actions
import { logout, update } from '../../../store/featues/user/actions'

// ** types imports
import { UserInitialState } from './types'

const initialState: UserInitialState = {
    user: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: update,
        logoutUser: logout,
    },
})

export const userReducer = userSlice.reducer
export const { updateUser, logoutUser } = userSlice.actions
